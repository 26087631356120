import React, { useRef } from 'react';
import { MdOutlineMailOutline } from "react-icons/md";
import { BsLinkedin, BsGithub } from "react-icons/bs";
import emailjs from "emailjs-com";


import './contact.css'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_xvfocm8', 'template_3lp232q', form.current, 'qpA6LUf1_pppdMHtn')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    e.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get in touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <BsLinkedin className='.contact__option-icon' />
            <h4>Linkedin</h4>
            <h5>JohanFire</h5>
            <a href='https://www.linkedin.com/in/johanfire/'
              target='_blank'
            >Send me a message</a>
          </article>

          {/* <article className="contact__option">
            <MdOutlineMailOutline className='.contact__option-icon' />
            <h4>Email</h4>
            <h5>johan@johanfire.com</h5>
            <a href='mailto:johan@johanfire.com'
              target='_blank'
            >Send me an email</a>
          </article> */}
          
          {/* <article className="contact__option">
            <BsGithub className='.contact__option-icon' />
            <h4>Github</h4>
            <h5>JohanFire</h5>
            <a href='https://github.com/JohanFire'
              target='_blank'
            >Go to Github</a>
          </article> */}
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
        </form>

      </div>
    </section>
  )
}

export default Contact