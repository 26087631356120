import React from 'react'

import './portfolio.css'
import PROJECT from "../../assets/project.png"
import TIKTOK from "../../assets/tiktok.jpg"
import NOTES_APP from "../../assets/notes_app.png";
import TO_DO_LIST from "../../assets/to_do_list.jpeg"
import ECOMMERCE from "../../assets/ecoomerce.jpg"

const data = [
    {
        id: 1,
        image: PROJECT,
        title: "Enterprise System",
        tech_stack: "C# (.NET), SQL Server",
        description: "(Finished) Sales, orders, & products Internal System for a real company",
        github: "",
        demo: ""
    },
    {
        id: 2,
        image: TIKTOK,
        title: "TikTok Clone",
        tech_stack: "Django + React Native",
        description: "(Working on) Replicating the complete TikTok app",
        github: "https://github.com/JohanFire/TikTok-Clone",
        demo: ""
    },
    {
        id: 3,
        image: NOTES_APP,
        title: "Notes App",
        tech_stack: "Django + React",
        description: "(Finished) Notes app using django + react integration.",
        github: "https://github.com/JohanFire/Notes_App--django-react",
        demo: "https://www.youtube.com/watch?v=0VhKaPFDnRA"
    },
    {
        id: 4,
        image: TO_DO_LIST,
        title: "TO DO List App",
        tech_stack: "Django + React Native",
        description: "(Finished) Notes app using django + react integration.",
        github: "https://github.com/JohanFire/ToDo--django-react_native",
        demo: ""
    },
    {
        id: 5,
        image: ECOMMERCE,
        title: "Django eCommerce",
        tech_stack: "Django + HTML & CSS (Bootstrap)",
        description: "(Working on)",
        github: "https://github.com/JohanFire/django_Ecommerce",
        demo: ""
    },
]

const Portfolio = () => {
    return (
        <section id='portfolio'>
            <h5>My Personal Projects</h5>
            <h2>Portfolio</h2>

            <div className="container portfolio__container">
                {
                    data.map(({ id, image, title, tech_stack, description, github, demo}) => {
                        return (
                            <article key={id} className="portfolio__item">
                                <div className="portfolio__item-image">
                                    <div>
                                        <img src={image} alt={title} />
                                    </div>
                                    <h3>{title}</h3>
                                    <h6>{tech_stack}</h6>
                                    <h5>{description}</h5>
                                    <div className="portfolio__item-cta">
                                        <a
                                            href={github !== "" ? github : null}
                                            target="_blank" className="btn">Github
                                        </a>
                                        <a
                                            href={demo !== "" ? demo : null}
                                            target="_blank" className="btn btn-primary">Demo
                                        </a>
                                    </div>
                                </div>
                            </article>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Portfolio