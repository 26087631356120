import React, { useState } from 'react'
import { AiOutlineHome, AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiMessage2Line } from "react-icons/ri";
import { HiBriefcase } from "react-icons/hi";

import './nav.css'

const Nav = () => {
    const [activeNav, setActiveNav] = useState('#')

    return (
        <nav>
            <a href='#' className={activeNav === '#' ? 'active' : ''}
                onClick={() => setActiveNav('#')}
            ><AiOutlineHome /></a>
            <a href='#about' className={activeNav === '#about' ? 'active' : ''}
                onClick={() => setActiveNav('#about')}
            ><AiOutlineUser /></a>
            <a href='#experience' className={activeNav === '#experience' ? 'active' : ''}
                onClick={() => setActiveNav('#experience')}
            ><BiBook /></a>
            <a href='#portfolio' className={activeNav === '#services' ? 'active' : ''}
                onClick={() => setActiveNav('#services')}
            ><HiBriefcase /></a>
            <a href='#contact' className={activeNav === '#contact' ? 'active' : ''}
                onClick={() => setActiveNav('#contact')}
            ><RiMessage2Line /></a>
            {/* <a href='#services' className={activeNav === '#services' ? 'active' : ''}
                    onClick={() => setActiveNav('#services')}
                ><HiBriefcase /></a> */}
        </nav>
    )
}

export default Nav