import React from 'react'
import { BsFillPatchCheckFill } from "react-icons/bs";
import { TbCheck } from "react-icons/tb";
import { FaLanguage } from "react-icons/fa";
import { GrLanguage } from "react-icons/gr";
import { LuLanguages } from "react-icons/lu";

import './experience.css'

const Experience = () => {
    return (
        <section id='experience'>
            <h5>Hard Skills</h5>
            <h2>Experience</h2>

            <div className="container experience__container">

                <div className="experience__languages"
                    style={{ background: "#d6cbcb" }}>
                    <h3 style={{ color: '#070707' }}>Languages</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <FaLanguage className="experience__details-icon"
                                style={{ color: '#070707' }}
                            />
                            <div>
                                <h4
                                    style={{ color: '#070707' }}
                                >Spanish</h4>
                                <small className='text-light'
                                    style={{ color: '#070707' }}
                                >Native</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <GrLanguage className="experience__details-icon-variant"
                                style={{ background: "#d6cbcb" }}
                            />
                            <div>
                                <h4
                                    style={{ color: '#070707' }}
                                >English</h4>
                                <small className='text-light'
                                    style={{ color: '#070707' }}
                                >Fluent (B2)</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <LuLanguages className="experience__details-icon"
                                style={{ color: '#070707' }}
                            />
                            <div>
                                <h4
                                    style={{ color: '#070707' }}
                                >German</h4>
                                <small className='text-light'
                                    style={{ color: '#070707' }}
                                >Intermediate (B1)</small>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__backend">
                    <h3>Backend Development</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>Python (Django, Flask)</h4>
                                <small className='text-light'>+1 year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>Node.js (Express)</h4>
                                <small className='text-light'>+1 year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>C#(.NET)</h4>
                                <small className='text-light'>6 years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>SQL</h4>
                                <small className='text-light'>+5 year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>NoSQL (DynamoDB)</h4>
                                <small className='text-light'>+6 months</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>Cloud (AWS)</h4>
                                <small className='text-light'>+6 months</small>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__frontend">
                    <h3>Frontend Development</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>HTML</h4>
                                <small className='text-light'>+2 years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>CSS</h4>
                                <small className='text-light'>+2 years</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>Javascript</h4>
                                <small className='text-light'>+1 year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>React</h4>
                                <small className='text-light'>+1 year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Bootstrap</h4>
                                <small className='text-light'>+6 months</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Tailwind</h4>
                                <small className='text-light'>+6 months</small>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__mobile">
                    <h3>Mobile Development</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>React Native</h4>
                                <small className='text-light'>+6 months</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Xamarin</h4>
                                <small className='text-light'>6 months</small>
                            </div>
                        </article>
                    </div>
                </div>

                <div className="experience__ia-and-ml">
                    <h3>IA & ML with Python</h3>
                    <div className="experience__content">
                        <article className='experience__details'>
                            <BsFillPatchCheckFill className="experience__details-icon" />
                            <div>
                                <h4>OpenCV</h4>
                                <small className='text-light'>+1 year</small>
                            </div>
                        </article>
                        <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Tensorflow</h4>
                                <small className='text-light'>+6 months</small>
                            </div>
                        </article>
                        {/* <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Pandas</h4>
                                <small className='text-light'>+3 months</small>
                            </div>
                        </article> */}
                        {/* <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Matplotlib</h4>
                                <small className='text-light'>+3 months</small>
                            </div>
                        </article> */}
                        {/* <article className='experience__details'>
                            <TbCheck className="experience__details-icon" />
                            <div>
                                <h4>Numpy</h4>
                                <small className='text-light'>+3 months</small>
                            </div>
                        </article> */}
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Experience