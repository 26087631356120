import React from 'react'

import './header.css'
import CTA from "./CTA";
import johan from '../../assets/johan_9-16_shorter.JPG'
import HeaderSocial from "./HeaderSocials";

const Header = () => {
    return (
        <header>
            <div className="container header__container">
                <h5>Hello, I'm</h5>
                <h1>Johan Tristán</h1>
                <h5 className="text-light">Software Developer based in México</h5>
                <CTA />
                <HeaderSocial />

                <div className='me'>
                    <img src={johan} alt="" />
                </div>

                {/* <a href='#contact' className='scroll__down avoid-purple-link'>Scroll Down</a> */}
            </div>
        </header>
    )
}

export default Header