import React from 'react'
import { BsLinkedin, BsGithub } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";

import './footer.css'

const Footer = () => {
    return (
        <footer>
            <a href="#" className='footer__logo'>JohanFire</a>

            <ul className='permalinks'>
                <li><a href='#'>Home</a></li>
                <li><a href='#about'>About Me</a></li>
                <li><a href='#experience'>Experience</a></li>
                {/* <li><a href='#services'>Services</a></li> */}
                <li><a href='#portfolio'>Portfolio</a></li>
                {/* <li><a href='#testimonials'>Testimonials</a></li> */}
                <li><a href='#contact'>Contact</a></li>
            </ul>

            <div className="footer__socials">
                <a href='https://www.linkedin.com/in/johanfire/' target='_blank' ><BsLinkedin /></a>
                <a href='https://github.com/JohanFire' target='_blank' ><BsGithub /></a>
                <a href='https://instagram.com/JohanFireOK' target='_blank' ><AiFillInstagram /></a>
            </div>

            <div className="footer__copyright">
                <small>Last porftolio update: June/01/2023 <br/></small>
                <small>&copy; JohanFire. All rights reserved.</small>
            </div>

        </footer>
    )
}

export default Footer