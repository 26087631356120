import React from 'react'
import { BsLinkedin, BsGithub } from "react-icons/bs";

const HeaderSocials = () => {
    return (
        <div className='header__socials'>
            <a href='https://www.linkedin.com/in/johanfire/' target='_blank' className='avoid-purple-link'><BsLinkedin /></a>
            <a href='https://github.com/JohanFire' target='_blank' className='avoid-purple-link'><BsGithub /></a>
            {/* <a href='https://www.instagram.com/johanfireok/' target='_blank' className='avoid-purple-link'><BsInstagram/></a> */}
        </div>
    )
}

export default HeaderSocials