import React from 'react'
import { FaAward } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { VscFolderLibrary } from "react-icons/vsc";

import './about.css'
import ai_johan from "../../assets/ai_johan.JPG";

const About = () => {
    return (
        <section id='about'>
            <h5>Get to know me better</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={ai_johan} alt="About Image" />
                    </div>
                </div>

                <div className="about__content">
                    <div className="about__cards">
                        <article className='about__card'>
                            <FaAward className='about__icon' />
                            <h5>Experience</h5>
                            <small>+1 year in the Tech Industry</small>
                        </article>

                        <article className='about__card'>
                            <ImBooks className='about__icon' />
                            <h5>Self-taught</h5>
                            <small>Learning for +6 years</small>
                        </article>

                        <article className='about__card'>
                            <VscFolderLibrary className='about__icon' />
                            <h5>Projects</h5>
                            <small>A wide variety</small>
                        </article>
                    </div>
                    <p>
                        Hi, my name is Johan Desailly Tristán García and I'm a Mechatronic Engineer. <br />
                        I consider myself as an enthusiast for learning,
                        passionate about what I do & putting my maximum effort to reach goals.
                        <br />
                        I enjoy solving problems, always analyzing the easiest & effective way to solve them.
                    </p>

                    {/* <a href='#contact' className='btn btn-primary'>Contact Me</a> */}
                </div>
            </div>
        </section>
    )
}

export default About
